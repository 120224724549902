.footer {
    width: 100%;
    background-color: #f5f5f5;
}

.h-95 {
    height: 95vh;
}

.h-97 {
    height: 97vh;
}

.h-5 {
    height: 5vh;
}

.h-3 {
    height: 3vh;
}